<template>
  <BaseModal
    name="ModalPipelineAdicionar"
    id="ModalPipelineAdicionar"
    idModal="ModalPipelineAdicionar"
    size="xl"
    title="Novo CRM"
    @hidden="hideDataPipeline()"
  >
    <div v-if="tags_props">
      <div class="flex-titulo">
        <div style="width: 40%">
          <h4 class="nome">Nome do CRM</h4>
          <input
            style="width: 100%"
            type="text"
            placeholder="Nome do meu CRM"
            v-model="titulo"
          />

          <div
            style="
              display: flex;
              flex-direction: column;
              gap: 17px;
              margin-top: 13px;
            "
          >
            <span class="titleError text-danger" v-show="titleRequired"
              >Por favor, insira o nome do CRM</span
            >
            <span class="titleError text-danger" v-if="titleSpace"
              >Espaços inválidos</span
            >
            <span
              class="titleError text-danger"
              v-show="titleLength"
              v-if="titulo.length < 6 || titulo.length > 40"
              >Mínimo de 6 caracteres e máximo de 40 caracteres</span
            >
          </div>
        </div>
        <div style="width: 40%; margin-top: -27px; position: relative;">
          <div class="add-link-body">
            <div
              style="
                display: flex;
                gap: 8px;
                align-items: center;
                margin-bottom: 8px;
              "
            >
              <label class="nome mb-0" for="tag_exit">Tag saída</label>
              <img
                width="17px"
                height="17px"
                src="@/assets/img/icons/help-circle.svg"
                alt="icon"
                v-b-tooltip.hover.top
                title="Lead irá receber uma tag ao ser removido do CRM"
              />
            </div>
            <router-link
              v-if="!tags_props.length && verificUserLevel"
              :to="{ name: 'Tags' }"
              class="add-link link mb-0"
              >Deseja criar uma tag?
            </router-link>
          </div>
          <multiselect
            id="tag_exit"
            label="name"
            v-model="tag_saida"
            :options="
              tags_props.filter((tag) =>
                selected_form.every((form) => form.id != tag.id)
              )
            "
            track-by="id"
            placeholder="Selecione uma tag"
            selectLabel=""
            deselectLabel=""
            selectedLabel=""
            :allow-empty="false"
            :searchable="true"
            :hideSelected="true"
          >
            <span slot="noResult">Oops! Nenhuma tag encontrada.</span>
          </multiselect>
          <span v-if="requiredExitTag" class="titleError text-danger exit-tag-required">
            Tag de saída obrigatório 
          </span>
        </div>
        <div style="width: 20%;">
          <BaseButton
            variant="link-info"
            @click="openModal('ModalAddTagPipeline')"
          >
            Criar tag
          </BaseButton>
        </div>
      </div>

      <div   class="grid " :class="{'spacing-bottom':flagNewBords}">
        <div
          class="boards"
          v-for="(item, index) in selected_form"
          :key="index"
          :class="`${item.color ? item.color : 'default'}_color`"
        >
          <div style="display: flex; justify-content: space-between">
            <label
              class="nome"
              style="color: white; font-weight: 500 !important"
              :for="`board${index}`"
              >Board 0{{ index + 1 }}</label
            >
            <div>
              <div class="image-control">
                <img
                  :class="{ 'disabled-arrow': index == 0 }"
                  class="first-arrow"
                  src="@/assets/editor/chevron.svg"
                  @click="index == 0 ? '' : moveUp(index)"
                />
                <button
                  @click="
                    selected_form.length == 1 ? '' : diminuirContador(index)
                  "
                >
                  <img
                    class="icon"
                    src="@/assets/icons/calendar/trash.svg"
                    alt="trash"
                    :style="
                      selected_form.length == 1
                        ? 'filter: invert(50%);'
                        : 'filter: brightness(2);'
                    "
                  />
                </button>
                <img
                  :class="{
                    'disabled-arrow': index + 1 == selected_form.length,
                  }"
                  class="last-arrow"
                  src="@/assets/editor/chevron.svg"
                  @click="
                    index + 1 == selected_form.length ? '' : moveDown(index)
                  "
                />
              </div>
            </div>
          </div>
          <multiselect
            :id="`board${index}`"
            label="name"
            v-model="selected_form[index]"
            :options="
              tags_props.filter(
                (tag) =>
                  selected_form.every((form) => form.id != tag.id) &&
                  tag_saida.id != tag.id
              )
            "
            track-by="id"
            placeholder="Selecione"
            selectLabel=""
            deselectLabel=""
            selectedLabel=""
            :allow-empty="false"
            :searchable="true"
            :hideSelected="true"
            @update="updateSelecteds()"
          >
            <span slot="noResult">Nenhuma tag encontrada.</span>
          </multiselect>
        </div>
        <div class="boards" v-if="selected_form.length <= 7">
          <label
            class="nome"
            :style="
              selected_form.every((item) => item.hasOwnProperty('id'))
                ? ''
                : 'color: #dadada;'
            "
            :for="`board`"
            >Adicionar board</label
          >
          <div
            class="add-board"
            :style="
              selected_form.every((item) => item.hasOwnProperty('id'))
                ? ''
                : 'border-color: #dadada; color: #dadada; background-color: #efefef;'
            "
            @click="
              selected_form.every((item) => item.hasOwnProperty('id'))
                ? selected_form.push('null')
                : ''
            "
          >
            +
          </div>
        </div>
      </div>
      <GrCollapsible v-if="flagNewBords">

      <GrCollapsibleItem >
        <template #header>
          <div class="collapsible-header">
            <label>Tipo de board de saída</label>
            <p>
              Escolha a melhor opção de saída para o seu crm.
            </p>
          </div>
        
        </template>
        <template #content>
          <div  class="flex-new-bord spacing-30" >
            <div class="content-new-bord">
              <div class="old" @click="getColumnOutSeparated = 0">
                <div class="switch">
                  <label> Board Único</label>
                  <GrSwitchCircle :active="getColumnOutSeparated === 0" />
                </div>
                <div class="card-feature-win-loss">
                    <p class="color-bord color-bord-black">Tag de saída</p>
                </div>
              </div>
            
              <div class="new" @click="getColumnOutSeparated = 1">
                <div class="switch">
                  <label> Board Ganho e Perdido</label>
                  <GrSwitchCircle :active="getColumnOutSeparated === 1" />
                </div>
                <div class="cards">
                
                  <div v-for="(item) in listBords" class="card-feature-win-loss">
                    <p :class="`color-bord color-bord-${item.cor}`">{{ item.name }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </GrCollapsibleItem>
      </GrCollapsible>
    </div>
    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Fechar
      </BaseButton>
      <BaseButton variant="primary" @click="onSubmit"> Criar CRM </BaseButton>
    </template>
    
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <ModalAddTagPipeline
      :tagsProps="tags_props"
    />
  </BaseModal>
</template>

<script>
import PipelineService from "@/services/resources/PipelineService";
const servicePipeline = PipelineService.build();
import { GrSwitchCircle } from "@/components/GrComponents/switch-circle";
import { GrCollapsible, GrCollapsibleItem } from "@/components/GrComponents/collaps";

import ModalAddTagPipeline from '@/components/Pipeline/ModalAddTagPipeline'
import Multiselect from "vue-multiselect";

export default {
  props: ["tags_props"],

  components: {
    Multiselect,
    GrSwitchCircle,
    GrCollapsible,
    GrCollapsibleItem,
    ModalAddTagPipeline,
  },
  data() {
    return {
      titleRequired: false,
      titleLength: false,
      titleSpace: false,
      columnOutSeparated: 0,
      requiredExitTag: false,
      titulo: "",
      tag: null,
      stops: [],
      listBords: [
        {
          name: "Tag de saída",
          cor: "black"
        },
        {
          name: "Ganho",
          cor: "green"
        },
        {
          name: "Perdido",
          cor: "red"
        }
      ],
      loading: false,
      selected_form: [],
      tag_saida: {},
      render: false,
      flagNewBords: false,
    };
  },
  computed: {
    getColumnOutSeparated:{
      get(){
        return this.columnOutSeparated 
      },
      set(){
        this.columnOutSeparated == 1 ? this.columnOutSeparated = 0 : this.columnOutSeparated = 1
      }
    },
    verificUserLevel() {
      var user = this.$store.getters.user.user.level;
      if (user === "owner" || user === "admin" || user == "gerent_seller") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    openModal(data) {
      this.$bvModal.show(data);
    },
    diminuirContador(index) {
      this.selected_form.splice(index, 1);
    },
    moveUp(idx) {
      var moving = this.selected_form[idx];
      this.selected_form.splice(idx, 1);
      this.selected_form.splice(idx - 1, 0, moving);
    },
    moveDown(idx) {
      var moving = this.selected_form[idx];
      this.selected_form.splice(idx, 1);
      this.selected_form.splice(idx + 1, 0, moving);
    },
    hideDataPipeline() {
      this.titulo = "";
      this.tag_saida = "";
      this.selected_form = [];
    },
    validSpace() {
      const regex = /  /;
      this.titleSpace = regex.test(this.titulo);
    },
    onSubmit() {
      var new_selected_form = this.selected_form.map((element) => element.id);
      this.requiredExitTag = false;

      var isDuplicate = new_selected_form.some(function (item, idx) {
        return new_selected_form.indexOf(item) != idx;
      });

      var tag_saida_is_duplicate = this.selected_form.indexOf(this.tag_saida);

      if (tag_saida_is_duplicate !== -1) {
        this.selected_form.splice(tag_saida_is_duplicate, 1);
      }
      const regex = /  /;
      if (!this.titulo) {
        this.titleRequired = true;
        this.titleLength = false;
        this.loading = false;
        return;
      } else if (
        this.titulo.length < 6 ||
        this.titulo.length > 40 ||
        regex.test(this.titulo)
      ) {
        this.titleLength = true;
        this.titleRequired = false;
        this.loading = false;
        return;
      } else {
        this.titleRequired = false;
        this.titleLength = false;
      }      
      if ( this.tag_saida.id === undefined || typeof parseInt(this.tag_saida.id ) !== 'number') {
        this.requiredExitTag = true;
        this.loading = false;
        return; 
      }
      if (isDuplicate === true) {
        this.$grToast.toast(
          "Existem campos selecionados com os mesmos boards",
          {
            title: "CRM",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          }
        );
        this.loading = false;
        return;
      } else {
        this.loading = true;
        this.$validator.validateAll().then((result) => {
          if (result) {
            var tags = Array.from(new_selected_form);
            var result_tags = [];
            for (let i = 0; i < tags.length; i++) {
              const element = tags[i];
              if (i <= this.selected_form.length && element != null) {
                result_tags.push(element + "");
              }
            }

            if (this.tag_saida.id) {
              result_tags.push(this.tag_saida.id.toString());
            }
            var data = {
              tag_exit: `${this.tag_saida.id}`,
              tags: result_tags,
              title: `${this.titulo}`,
              column_out_separated: this.getColumnOutSeparated,
            };
            servicePipeline
              .create(data)
              .then((resp) => {
                this.$grToast.toast("CRM criado com sucesso", {
                  title: "CRM",
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
                this.loading = false;
                localStorage.setItem(
                  "prev_selectedPipeline",
                  this.$route.params.id
                );
                localStorage.setItem("selectedPipeline", resp.messages.id);
                this.$emit("reload-list");
                this.titulo = "";
                this.selected_form = [];
                this.tag_saida = undefined;
                this.$bvModal.hide("ModalPipelineAdicionar");
              })
              .catch((err) => {
                this.$grToast.toast("Erro ao criar CRM", {
                  title: "CRM",
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
              })
              .finally(() => {
                this.loading = false;
                this.requiredExitTag = false;
              });
          } else {
            if (this.selected_form.length === 0) {
              this.$grToast.toast("Obrigatório selecionar os campos de board", {
                title: "CRM",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.loading = false;
              return;
            }
            if (this.selected_form.length < this.contador) {
              this.$grToast.toast("Obrigatório selecionar os campos de Board", {
                title: "CRM",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.loading = false;
              return;
            }
          }
        });
      }
    },
  },
  watch: {
    titulo: "validSpace",
  },
  async mounted(){
    this.flagNewBords = await this.$store.dispatch('getUniqueFlag', 'crm_out_separated')   
  }
};
</script>

<style lang="scss" scoped>
.exit-tag-required{
  position: absolute;
  bottom: -25px;
}
.w-100{
  width: 100%;
}
.color-bord{
  font-size: 16px;
  font-weight: 500;
  min-width: 180px ;
  &-black{
    color: var(--green-dark-800);
  }
  &-green{
    color: var(--greenn);
  }
  &-red{
    color: var(--red-500);
  }
}

.spacing{
  margin-top: 20px;
  &-30{
    margin-top: 30px !important;
  }
  &-bottom{
    margin-bottom: 30px !important;
  }
}

.collapsible-header{

  p{
    font-size: 14px;
    color: var(--gray-200)
  }
}
.card-feature-win-loss{
  background: var(--gray-50);
  padding: 20px 15px;
  border-radius: 10px;
}
.flex-new-bord{
  display: grid;
  gap: 1rem;
  margin-bottom: 1.5rem;
  align-items: top;
  .content-new-bord{
    display: flex;
    justify-content: space-between;
    .switch{
      top: 7px;
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
    .old,.new{
      border: solid 1px #eaeaea ;
      position: relative;
      border-radius: 10px;
      height: 100%;
      gap: 5px;
      display: grid;
      position: relative;
      padding: 20px;
    }

    .old{
      width: 30%;
    }
    .new{
      width: 68%;
      .cards{
        display: flex;
        gap: 20px;
      }
      &::after{
        content: 'Novidade';
        position: absolute;
        padding: 2px 7px;
        right: 0px;
        top: -15px;
        font-size: 12px;
        background-color: var(--secondary-750);
        color: var(--gray-white);
        font-weight: 600;
        border-radius: 10px;
      }
    }
  }
}
.boards {
  border: 1px solid #eaeaea;
  border-radius: 10px;
  padding: 10px;
  transition: all 0.3s;
}
.image-control {
  height: auto;
  display: flex;
  width: 35px;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  button {
    height: 24px;
    width: 24px;
    border-radius: 10px;
    transition: all 0.3s;
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    font-size: 25px;
    cursor: pointer;
    img {
      height: 15px;
    }
  }
  div {
    width: min-content;
  }
  .first-arrow {
    rotate: 90deg;
    height: 15px;
    filter: brightness(2);
  }
  .last-arrow {
    rotate: -90deg;
    height: 18px;
  }
  img {
    cursor: pointer;
  }
  .disabled-arrow {
    filter: invert(50%);
    cursor: default !important;
  }
}
.add-board {
  height: 50px;
  border-radius: 10px;
  width: 100%;
  background-color: transparent;
  border: 1px solid var(--greenn);
  color: var(--greenn);
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background-color: #05840a22;
  }
}
.img {
  cursor: pointer;
}
.nome {
  font-weight: 600;
  font-size: 14px;
  &-white{
    color: white; 
    font-weight: 500 !important;
  }
}
.flex-titulo {
  display: flex;
  align-items: center;
  gap: 10px;
  .titleError {
    font-size: 12px;
    margin-top: -20px;
    font-weight: 500;
  }
}
.flex {
  display: flex;
  align-items: center;
  gap: 25px;
  position: fixed;
  right: 50px;
}
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}
.b-flex {
  margin-top: 27px;
  display: flex;
  gap: 6px;
}
.info_color {
  background: var(--bluee);
}
.danger_color {
  background: var(--red);
}
.warning_color {
  background: var(--orange);
}
.primary_color {
  background: var(--greenn-light);
}
.success_color {
  background: #2ed7ee;
}
.default_color {
  background: var(--gray01);
}
</style>