var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"products-cards"},[_c('div',{class:{
      'product-card': true,
      'cursor': true,
      'border': _vm.selectedIndex === _vm.index
    },staticStyle:{"width":"100%"},on:{"click":function($event){_vm.selected(_vm.index, _vm.workflow.category_id, _vm.sellers.find((x) => x.id === _vm.workflow.user_id), _vm.workflow.id)}}},[_c('div',{staticStyle:{"display":"flex","align-items":"center","width":"75%"}},[_c('div',{staticStyle:{"margin-right":"10px"}},[(_setup.userHasPhoto)?_c('div',[_c('img',{staticClass:"seller-photo",staticStyle:{"margin":"0 !important"},attrs:{"src":(_vm.sellers.find((x) => x.id === _vm.workflow.user_id).metas).find((x) => x.meta_key === 'photo')?.meta_value,"alt":""}})]):_c('div',{staticClass:"seller-no-ft"},[_c('h3',[_vm._v(" "+_vm._s(_setup.getMetaInitial(_vm.workflow.user_id))+" ")])])]),_c('span',{staticClass:"sellers-name cursor"},[_vm._v(" "+_vm._s(_vm.categories.find((x) => x.id === _vm.workflow.category_id).title)+" "),_c('label',{staticStyle:{"margin":"0 !important"},attrs:{"for":""}},[_vm._v(_vm._s(_vm.sellers.find((x) => x.id === _vm.workflow.user_id)?.first_name ? _vm.sellers.find((x) => x.id === _vm.workflow.user_id)?.first_name : "")+" "+_vm._s(_vm.sellers.find((x) => x.id === _vm.workflow.user_id)?.last_name ? _vm.sellers.find((x) => x.id === _vm.workflow.user_id)?.last_name : ""))])])]),_c('div',{staticClass:"container-progress"},[_c('div',{class:{
        'progress': true,
        'progress-0': _vm.workflow.completed_lead_activities_count === 0,
        'progress-100': _vm.workflow.completed_lead_activities_count === _vm.workflow.activities_count
      }},[_c('p',[_vm._v(_vm._s(_vm.workflow.completed_lead_activities_count)+"/"+_vm._s(_vm.workflow.activities_count))])]),_c('div',{staticClass:"date"},[_c('p',[_vm._v(_vm._s(_setup.date(_vm.workflow.created_at)))])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }