import Rest from '@/services/Rest';

/**
 * @typedef {WorkflowService}
 */
export default class WorkflowService extends Rest {
  /**
   * @type {String}
   */
  static resource = 'workflow'
}