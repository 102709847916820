<template>
  <div class="card">
    <div class="time-icon">
      <img :src="require(`@/assets/img/icons/${historyIcon[action.type]}.svg`)" alt="Ícone referente a ação">
    </div>
    <div>
      <p class="data">{{ action.created_at | datetimem }}</p>
      <p v-if="action.type == 'register-form'">
        O lead se cadastrou no formulário
        "{{ propName }}"
        {{
          action.leadscore
            ? `e adicionou ${action.leadscore}° de temperatura `
            : ""
        }}
      </p>
      <p v-else-if="action.type == 'associate-company'">
        O lead foi associado à empresa "{{
          propName
        }}"
      </p>
      <p v-else-if="action.type == 'desassociate-company'">
        O lead foi desassociado à empresa "{{
          propName
        }}"
      </p>
      <p v-else-if="action.type == 'note'">
        {{
          getUserName
            ? `O usuário ${getUserName} `
            : "Um usuário"
        }}
        adicionou a nota "{{ action.value }}"
      </p>
      <p v-else-if="action.type == 'associate-user'">
        {{
          getUserName
            ? `O usuário ${getUserName} `
            : "Um usuário"
        }}
        foi associado ao lead
      </p>
      <p v-else-if="action.type == 'updated-user'">
        O vendedor do lead foi alterado{{
          getUserValue ?? ""
        }} {{  getUserName ? `por ${getUserName} ` : '' }}
      </p>
      <p v-else-if="action.type == 'delete-note'">
        {{
          getUserName
            ? `O usuário ${getUserName} `
            : "Um usuário"
        }}
        removeu a nota "{{ action.value }}"
      </p>
      <p v-else-if="action.type == 'update-note'">
        {{
          getUserName
            ? `O usuário ${getUserName} `
            : "Um usuário"
        }}
        atualizou a nota "{{ action.value }}"
      </p>
      <p v-else-if="action.type == 'associate-tag'">
        {{
          getUserName
            ? 'O usuário ' + getUserName +
              " adicionou a tag"
            : "Foi adicionada a tag"
        }}
        "{{ propName }}"
        {{
          action.leadscore
            ? `e adicionou ${action.leadscore}° de temperatura `
            : ""
        }}
      </p>
      <p
        v-else-if="
          action.type == 'activitie-failed' ||
          action.type == 'lead-gain' ||
          action.type == 'pipeline_automation' ||
          action.type == 'lead-open' ||
          action.type == 'done-activitie'
        "
      >
        {{
          getUserName
            ? `O usuário ${getUserName} `
            : "Um usuário"
        }}
        {{ action.value }}
      </p>
      <p v-else-if="action.type == 'move-board'">
        {{
          getUserName
            ? `O usuário ${getUserName} `
            : "Um usuário"
        }}
        adicionou a tag "{{ propName }}"
        {{
          action.leadscore
            ? `e adicionou ${action.leadscore}° de temperatura `
            : ""
        }}
      </p>
      <p v-else-if="action.type == 'remove-board'">
        {{
          getUserName
            ? `O usuário ${getUserName} `
            : "Um usuário"
        }}
        removeu a tag "{{ propName }}"
        {{
          action.leadscore
            ? `e adicionou ${action.leadscore}° de temperatura `
            : ""
        }}
      </p>
      <p v-else-if="action.type == 'broadcast_email_delivered'">
        O lead recebeu o e-mail "{{ propName }}"
      </p>
      <p v-else-if="action.type == 'broadcast_email_opened'">
        O lead abriu o e-mail "{{ propName }}"
        {{
          action.leadscore
            ? `e adicionou ${action.leadscore}° de temperatura `
            : ""
        }}
      </p>
      <p v-else-if="action.type == 'funnel_email_opened'">
        O lead abriu o e-mail "{{ propName }}"
        {{
          action.leadscore
            ? `e adicionou ${action.leadscore}° de temperatura `
            : ""
        }}
      </p>
      <p v-else-if="action.type == 'funnel_email_delivered'">
        O lead recebeu o e-mail "{{ propName }}"
        {{
          action.leadscore
            ? `e adicionou ${action.leadscore}° de temperatura `
            : ""
        }}
      </p>
      <p v-else-if="action.type == 'view'">
        O lead acessou a página
        {{ propName }}
        {{
          action.leadscore
            ? `e adicionou ${action.leadscore}° de temperatura `
            : ""
        }}
      </p>
      <p v-else-if="action.type == 'message'">
        {{ action.value }} de 
        {{ getUserName }}
        {{
          action.leadscore
            ? ` e adicionou ${action.leadscore}° de temperatura `
            : ""
        }}
      </p>
      <p v-else-if="action.type == 'broadcast_email_clicked'">
        O lead clicou no e-mail "{{ propName }}" no dia
        {{ action.created_at | datetimem }}
        {{
          action.leadscore
            ? `e adicionou ${action.leadscore}° de temperatura `
            : ""
        }}
      </p>
      <p v-else-if="action.type == 'funnel_email_clicked'">
        O lead clicou no e-mail
        "{{ propName }}" no dia
        {{ action.created_at | datetimem }}
        {{
          action.leadscore
            ? `e adicionou ${action.leadscore}° de temperatura `
            : ""
        }}
      </p>
      <p v-else-if="action.type == 'unsubscribe'">
        {{ action.value }}
      </p>
      <p v-else-if="action.type == 'desassociate-tag'">
        {{
          getUserName
            ? 'O usuário ' + getUserName +
              " removeu a tag"
            : "Foi removida a tag "
        }}
        "{{ propName }}"
      </p>
      <p v-else-if="action.type == 'leadscore'">
        {{
          getUserName
            ? 'O usuário ' + getUserName
            : "Um usuário "
        }}
        ajustou a temperatura do lead para {{ action.leadscore }}°
      </p>
      <p v-else-if="action.type == 'inactive'">
        {{
          getUserName
            ? 'O usuário ' + getUserName
            : "Um usuário "
        }}
        desativou o lead
      </p>
      <p v-else-if="action.type == 'leadscore-self'">
        A temperatura do lead subiu para {{ action.leadscore }}°
      </p>
      <p v-else-if="action.type == 'event.schedule'">
        Uma reunão foi agendada com o vendedor(a) {{decodedActionValue.name}} para a data {{ decodedActionValue.formattedDate }}
      </p>
      <p v-else-if="action.type == 'event.reschedule'">
        Uma reunião foi reagendada com o vendedor(a) {{ decodedActionValue.name }} para a data {{ decodedActionValue.formattedDate }}
      </p>
      <p v-else-if="action.type == 'event.cancelschedule'">
        A reunião agendada com o vendedor(a) {{ action.value }} foi cancelada
      </p>
    </div>
  </div>
</template>
<script>
import PageService from "@/services/resources/PageService";
const servicePage = PageService.build();
import FormService from "@/services/resources/FormService";
const serviceForm = FormService.build();
import CompanyService from "@/services/resources/CompanyService";
const serviceCompanies = CompanyService.build();
import EmailService from "@/services/resources/EmailService";
const serviceEmail = EmailService.build();
import FunnelService from "@/services/resources/FunnelService";
const serviceFunnel = FunnelService.build();
import TagService from "@/services/resources/TagService";
const serviceTags = TagService.build();
import moment from "moment-timezone";

export default {
  props: ["action", "users"],
  data() {
    return {
      historyIcon: {
        'funnel_email_opened': 'email-lead',
        'funnel_email_delivered' : 'email-lead',
        'broadcast_email_opened' : 'email-lead',
        'broadcast_email_delivered' : 'email-lead',
        'unsubscribe' : 'email-lead',
        'desassociate-company' : 'companieColor',
        'associate-company' : 'companieColor',
        'view' : 'eye',
        'note' : 'notas',
        'delete-note' : 'notas',
        'update-note' : 'notas',
        'associate-tag': 'tag-lead',
        'desassociate-tag': 'tag-lead',
        'move-board': 'tag-lead',
        'remove-board': 'tag-lead',
        'pipeline_automation': 'tag-lead',
        'done-activitie': 'check-circle',
        'lead-open': 'corner-up-left',
        'register-form': 'formulario',
        'activitie-failed': 'thumbs-down',
        'lead-gain': 'thumbs-up',
        'message': 'message',
        'broadcast_email_clicked' : 'cursor-click',
        'funnel_email_clicked' : 'cursor-click',
        'leadscore' : 'thermometer',
        'leadscore-self' : 'thermometer',
        'associate-user' : 'user',
        'updated-user' : 'user',
        'inactive' : 'user',
        'event.schedule': 'spotter',
        'event.reschedule': 'spotter', 
        'event.cancelschedule': 'spotter',
      },
      propName: 'Carregando...',
    };
  },
  computed: {
    getUserValue() {
      if (this.action.value && this.action.value !== '0') {
        var result = this.users.find((x) => x.id == this.action.value);
        if (result) {
          return ` para o usuário ${result.first_name == '' ? result.email : result.first_name}`;
        }
        return ''
      }else{
        return ' para Sem vendedor'
      }
    },
    getUserName(){
      if (this.action.user_id) {
        var result = this.users.find((x) => x.id == this.action.user_id);
        if (result) {
          return result.first_name;
        }
        return "";
      }
      return '' 
    },
    decodedActionValue(){
      if (this.action.type === "event.schedule" || this.action.type === "event.reschedule") {
        let actioData = JSON.parse(this.action.value);
        actioData.formattedDate = moment(actioData.data).format("DD/MM/YYYY [às] HH:mm");

        return actioData;
      }
    }
  },
  methods: {
    filterForm() {
      serviceForm
        .read({ id: `${this.action.value}` })
        .then((resp) => {
          this.propName = resp.title
        })
        .catch(() => {
          this.propName = "Desconhecido"
        });
    },
    getCompanyName() {
      var data = {
        id: `members/${this.action.value}`,
      };
      serviceCompanies.read(data).then((resp) => {
        resp.name = `${resp.legal_name ?? resp.name}`;
        this.propName = resp.name
      });
    },
    filterEmail() {
      serviceEmail
        .read(this.action.value)
        .then((resp) => {
          this.propName = resp.title;
        })
        .catch(() => {
          this.propName = "Desconhecido";
        });
    },
    filterFunnelEmail() {
      serviceFunnel
        .read(`email/${this.action.value}`)
        .then((resp) => {
          this.propName = resp.title;
        })
        .catch(() => {
          this.propName = "Desconhecido";
        });
    },
    filterPage() {
      let data = {
        id: `get/${this.action.value}`,
      };
      servicePage
        .read(data)
        .then((resp) => {
          this.propName = resp.title;
        })
        .catch((error) => {
          log(error);
          this.propName = 'Desconhecida';
        });
    },
    filterTag() {
      serviceTags
        .read(`/show/${this.action.value}`)
        .then((resp) => {
          this.propName = resp.name;
        })
        .catch((err) => {
          this.propName = 'Desconhecida';
        });
    },
  },
  mounted() {
    if (this.action.type == 'register-form') {
      this.filterForm()
    } else if (this.action.type == 'desassociate-company' || this.action.type == 'associate-company') {
      this.getCompanyName()
    } else if (this.action.type == 'broadcast_email_delivered' || this.action.type == 'broadcast_email_opened' || this.action.type == 'broadcast_email_clicked') {
      this.filterEmail()
    } else if (this.action.type == 'funnel_email_opened' || this.action.type == 'funnel_email_delivered' || this.action.type == 'funnel_email_clicked') {
      this.filterFunnelEmail()
    } else if (this.action.type == 'view') {
      this.filterPage()
    } else if (this.action.type == 'associate-tag' || this.action.type == 'move-board' || this.action.type == 'remove-board' || this.action.type == 'desassociate-tag') {
      this.filterTag()
    }
  },
};
</script>

<style scoped lang="scss">
.time-icon {
  width: 50px;
  height: 50px;
  background: #f7f7f7;
  border-radius: 50%;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    position: relative;
    z-index: 2;
  }
  &::after {
    content: "";
    border-bottom: 165px dashed #f7f7f7;
    z-index: -1;
    width: 3px;
    display: block;
    position: absolute;
    z-index: -5;
    top: 48px;
  }
}

p:last-child {
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}

.img-icon {
  width: 20px;
  height: 20px;
}

.card {
  border: none;
  padding: 20px 10px;
  display: grid;
  grid-template-columns: 50px 1fr;
  align-items: center;
  gap: 15px;
}

.data {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  color: var(--gray01);
}

</style>
