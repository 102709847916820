<template>
  <div class="products-cards">
    <div
      @click="selected(index, workflow.category_id, sellers.find((x) => x.id === workflow.user_id), workflow.id)"
      :class="{
        'product-card': true,
        'cursor': true,
        'border': selectedIndex === index
      }" 
      style="width: 100%;"
    >
      <div style="display: flex; align-items: center; width: 75%;">
        <div style="margin-right: 10px">
          <div v-if="userHasPhoto">
            <img
              style="margin: 0 !important"
              class="seller-photo"
              :src="(sellers.find((x) => x.id === workflow.user_id).metas).find((x) => x.meta_key === 'photo')?.meta_value"
              alt=""
            />
          </div>
          <div v-else class="seller-no-ft">
            <h3>
              {{ getMetaInitial(workflow.user_id) }}
            </h3>
          </div>
        </div>
        <span class="sellers-name cursor">
          {{ categories.find((x) => x.id === workflow.category_id).title }}
          <label
            style="margin: 0 !important"
            for=""
            >{{ sellers.find((x) => x.id === workflow.user_id)?.first_name ? sellers.find((x) => x.id === workflow.user_id)?.first_name : "" }}
            {{ sellers.find((x) => x.id === workflow.user_id)?.last_name ? sellers.find((x) => x.id === workflow.user_id)?.last_name : "" }}</label
          >
        </span>
      </div>
      <div class="container-progress">
        <div :class="{
          'progress': true,
          'progress-0': workflow.completed_lead_activities_count === 0,
          'progress-100': workflow.completed_lead_activities_count === workflow.activities_count
        }">
          <p>{{ workflow.completed_lead_activities_count }}/{{ workflow.activities_count }}</p>
        </div>
        <div class="date">
          <p>{{ date(workflow.created_at) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

import { onMounted, computed } from 'vue';

  const props = defineProps({
    sellers: {
      type: Array
    },
    categories: {
      type: Array
    },
    workflow: {
      type: Object
    },
    index: {
      type: Number
    },
    selected:{
      type: Function
    },
    selectedIndex: {
      type: Number
    }
  });

  const userHasPhoto = computed(() => {
    const seller = props.sellers.find(x => x.id === props.workflow.user_id);
    return props.workflow.user_id && seller?.metas?.some(x => x.meta_key === 'photo' && x.meta_value);
  });

  function getMetaInitial(seller) {
    let result = props.sellers.find((x) => x.id == seller);
    if (!result || (!result?.first_name && !result?.last_name && !result.email)) {
      return '??';
    }      
    let name = result?.first_name.trim() || "";
    let surname = result?.last_name.trim() || "";

    if (!name && !surname && result.email) {
      return result.email.substring(0, 2);
    }
    if (!name) {
      return surname.substring(0, 2);
    }
    if (!surname) {
      return name.substring(0, 2);
    }
    return name[0] + surname[0];
  }

  function date(date){
    const match = date.match(/(\d{4})-(\d{2})-(\d{2})/);
    return `${match[3]}-${match[2]}-${match[1]}`
  }

  onMounted(() => {
    if(props.index == 0){
      props.selected(props.index, props.workflow.category_id, props.sellers.find((x) => x.id === props.workflow.user_id));
    }
  });

</script>

<style lang="scss" scoped>
.products-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  width: 100%;
  justify-content: space-between;

  .border{
    border: 1px solid var(--greenn) !important;
  }

  .product-card {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    align-items: center;
    height: fit-content;
    border: 1px solid #e8e8e8;
    border-radius: 10px;
    padding: 12px;
    margin-bottom: 12px;
    position: relative;
    height: 70px;

    .product-photo {
      position: relative;
      width: 47px;
      min-width: 47px;
      height: 47px;
      border-radius: 10px;
      background-size: cover;
      border: 1px solid var(--white-medium);
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 20px;
        color: var(--greenn);
        font-weight: 600;
        letter-spacing: 2px;
        text-transform: uppercase;
        user-select: none;
      }
    }

    .product-infos {
      width: -webkit-fill-available;
      width: -moz-available;
      user-select: none;
      -webkit-user-drag: none;
      display: flex;
      flex-direction: column;
    }

    p {
      color: var(--gray01);
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
    }
  }
}
.cursor {
  cursor: pointer;
}

.seller-photo {
  width: 35px;
  height: 35px;
  border-radius: 25%;
  margin-top: 11px;
  object-fit: cover;
}

.seller-no-ft {
  width: 35px;
  height: 35px;
  border-radius: 25%;
  background: var(--greenn-transparent);
  color: var(--greenn) !important;
  border: 1px var(--greenn) solid;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-transform: uppercase;
  transition: all 0.3s;
  margin: 0 !important;

  h3 {
    text-align: center;
    font-size: 1rem;
    color: var(--greenn);
    margin: 0 !important;
    padding: 0 !important;
  }
}

.seller-no-ft:hover {
  background: var(--greenn2);
}

.sellers-name {
  white-space: nowrap;     
  overflow: hidden;        
  text-overflow: ellipsis;  
  width: 100%;  
  label {
    cursor: pointer
  }
}

.container-progress{
  width: 24%;
  display: grid;
  justify-content: center;
  
  .progress{
    background-color: #ff9e44;
    display: flex;
    justify-content: center;
    line-height: 1rem;
    height: 15px;
    font-size: 12px;
    font-weight: 400;
    border-radius: 1rem;
  }
  .progress-0{
    background-color: transparent;
    border: 1px solid var(--gray03);
  }
  .progress-100{
    background: var(--greenn);
  }
  .date{
    font-size: 10px;
  }
}
</style>