<script setup>
import { computed, getCurrentInstance, onMounted, ref } from 'vue';
import MidiaService from "@/services/resources/MidiaService";
import DateRangePicker from "../../../node_modules/vue2-daterange-picker";
import moment from "moment-timezone";
import MidiaLibrary from "@/components/Midias/MidiaLibrary";
import ModalViewMidia from "@/components/Pipeline/ModalViewMidia";
import lodash from "lodash";

const serviceMidia = MidiaService.build();

// Define props
const props = defineProps({
  localDate: {
    type: Object
  },
  inLeadView: {
    type: Boolean,
    default: false
  },
  buttonFooter: {
    type: Boolean,
    default: false
  },
  displaysSelectedFile: {
    type: Boolean,
    default: false
  },
  displaysSelectedData: {
    type: Boolean,
    default: true
  },
  saveOfCallBack: {
    type: Boolean,
    default: false
  },
  receivesValue: {
    type: Boolean,
    default: false
  },
  value: {
    type: Object,
    default: null
  }
});
// Variables
const midiasAssociation = ref(null);
const vm = getCurrentInstance();
const textMessage = ref("Olá, {{nome}}! Tudo bem? Me chamo {{usuário}}");
const dateRange = ref(moment());
const loadingFile = ref(false);
const file = ref(null);
const editMessage = ref(false);
const messagesFileTypes = ref([
  { title: "Texto", icon: "text", type: "texto" },
  { title: "Imagem", icon: "image-gray", type: "imagem" },
  { title: "Vídeo", icon: "rec", type: "vídeo" },
  { title: "Arquivo", icon: "file", type: "arquivo" },
  { title: "Áudio", icon: "mic-greenn", type: "áudio" },
  { title: "Buscar", icon: "search", type: "buscar" },
]);
const selected = ref({
  title: "Texto",
  icon: "text",
  type: "texto",
  index: 0,
});
// emits
const emit = defineEmits(['receiveAutomation', 'savedFunction']);

// function
const clearMidia = () => {
  file.value = null
  midiasAssociation.value = null
  vm.proxy.$refs.fileInput.value = null
};

const setTypeMessage = (item, index) => {
  selected.value = { ...item, index: index };
  clearMidia();
};

const openRangePicker = () => {
  vm.proxy.$refs.pickerdate.togglePicker();
};

const debouncedLog = lodash.debounce(() => {
  submitAutomation(true)
}, 500);

const inputSaved = () => {
  if (props.saveOfCallBack) {
    debouncedLog()
  }
}

const receiveMidia = (item) => {
  midiasAssociation.value = item;
  file.value = item;
  if (props.saveOfCallBack) {
    emit('savedFunction', submitAutomation)
  }
}

const uploadFile = () => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("file", file.value);
    formData.append("id", "/uploadNew");
    loadingFile.value = true;
    serviceMidia
      .createId(formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        if (!resp || !resp.id) {
          reject(false);
        }
        midiasAssociation.value = resp;
        resolve(true);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const clearInput = (event) => {
  event.target.value = null  
}
const receiveFile = (event) => {
  const fileReceived = event.target.files[0]
  
  if(fileReceived && selected.value.type == 'vídeo'){
    let fileExtension = fileReceived.name.endsWith('.mp4')

    if(!fileExtension){
      vm.proxy.$bvToast.toast("Tipo de aquivo não suportado", {
          title: "Greenn Sales",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
      clearInput(event)
      return
    }
  }
  if(fileReceived.size && fileReceived?.size >= 30000000){
    vm.proxy.$bvToast.toast("Tamanho do arquivo não suportado", {
        title: "Greenn Sales",
        variant: "danger",
        autoHideDelay: 5000,
        appendToast: true,
      });
    clearInput(event)
    return
  }

  file.value = fileReceived;
  if (props.saveOfCallBack) {
    emit('savedFunction', submitAutomation)
  }
};
const clearVeluesSelecteds = () => {
  textMessage.value = "Olá, {{nome}}! Tudo bem? Me chamo {{usuário}}";
  file.value = null;
  midiasAssociation.value = null
}

const setSelected = (item) => {

  const findItem = messagesFileTypes.value.find(types => types.type == item)
  const findIndex = messagesFileTypes.value.findIndex(types => types.type == item)

  selected.value = { ...findItem, index: findIndex }

}

const submitAutomation = async (noUploadFile) => {
  const isAudio  = computedTypeMessage.value.type == "áudio"
  
  let data = {
    message: isAudio ? "" : textMessage.value,
    audio: isAudio
  };

  if (props.displaysSelectedData) {
    data.data = moment(dateRange.value?.startDate).format('YYYY-MM-DD HH:mm:ss')
  }

  if (!midiasAssociation.value && computedTypeMessage.value.type !== "texto" && !noUploadFile) {
    await uploadFile().then((resp) => {
      if(resp){
        vm.proxy.$bvToast.toast("Midia salva com sucesso", {
          title: "Greenn Sales",
          variant: "success",
          autoHideDelay: 5000,
          appendToast: true,
        });
      }else{
        vm.proxy.$bvToast.toast("Não foi possivel subir a midia.", {
        title: "Greenn Sales",
        variant: "warning",
        autoHideDelay: 5000,
        appendToast: true,
      });
      }
    }).catch((error => {
      console.error("Erro ao enviar arquivo:", error);
      vm.proxy.$bvToast.toast("Não foi possivel subir a midia.", {
        title: "Greenn Sales",
        variant: "danger",
        autoHideDelay: 5000,
        appendToast: true,
      });
  })).finally(() =>{ loadingFile.value = false; });
  }

  if (midiasAssociation.value && computedTypeMessage.value.type !== "texto") {
    data.midias = [midiasAssociation.value.id ?? midiasAssociation.value];
  }
  emit("receiveAutomation", data, clearVeluesSelecteds);
};

const openFile = () => {
  vm.proxy.$bvModal.show('View-midia')
}

const setValueReceived = async () => {
  if (props.receivesValue) {
    textMessage.value = props.value.body
    try {
      const idMidia = JSON.parse(props.value.json)
      midiasAssociation.value = idMidia?.midias_ids[0]

      await serviceMidia.read(`/image/${midiasAssociation.value}`).then((resp) => {
        file.value = resp
        if (typeof resp.type == 'string') {

          const splitType = resp.type.split('/')
          const typeFile = splitType[0]

          switch (typeFile) {
            case 'audio':
              setSelected('áudio')
              break;
            case 'application':
              setSelected('arquivo')
              break;
            case 'image':
              setSelected('imagem')
              break;
            case 'video':
              setSelected('vídeo')
              break;
          }
        }
      })
    } catch (error) {
      console.error(error);
      return false
    }

  }
}

const selectFile = (type) => {
  if (type != 'buscar') {
    return vm.proxy.$refs.fileInput.click();
  }
  vm.proxy.$bvModal.show('midia-library');
};

const setEditMessage = () => {
  editMessage.value = !editMessage.value;
};
// computed
const isEditMessage = computed(() => {
  return editMessage.value;
});

const computedTypeMessage = computed(() => {
  return selected.value
})

const activeDescriptionMessage = computed(() => {
  return computedTypeMessage.value.type !== "áudio";
});

const dataSendMessage = computed(() => {
  const startDate = moment(dateRange.value?.startDate ?? dateRange.value);

  const formattedDate = startDate?.format("DD/MM/YY");
  const formattedTime = startDate?.format("HH:mm");

  return `Enviar ${formattedDate} às ${formattedTime}`;
});

const getTextButton = computed(() => {
  switch (selected.value.type) {
    case 'buscar':
      return "Biblioteca de mídias"
    case 'imagem':
      return "Selecione a imagem"
    default:
      return `Selecione o ${selected.value.type}`
  }
})

const getNameFile = computed(() => {
  return file.value.name ?? false
})

const getFormats = computed(() => {
  switch (selected.value.type) {
    case "imagem":
      return "png,jpeg,jpg e gif.";
    case "vídeo":
      return "mp4.";
    case "arquivo":
      return "pdf,csv e txt.";
    case "áudio":
      return "wav,ogg e mp3.";
    default:
      return false;
  }
});

const returnStatus = computed(() => {
  return !loadingFile.value ? (props.wizard ? 'Salvar' : "Agendar mensagem") : "Enviando.."
})

const switchSendFile = computed(() => {
  return props.saveOfCallBack ? !file.value : true
})

const acceptInput = computed(() => {
  switch (selected.value.type) {
    case "imagem":
      return "image/png,image/jpeg,image/jpg,image/gif";
    case "vídeo":
      return "video/mp4,video";
    case "arquivo":
      return "application/pdf,text/csv,text/plain,*";
    case "áudio":
      return "audio/wav,audio/ogg,audio/mp3";
    default:
      return "*";
  }
});

const activeCardSelectFile = computed(() => {
  return (
    selected.value.type !== "texto"
  );
});
//Mounted
onMounted(() => {
  let input = vm.proxy.$el.querySelector(".form-control, .reportrange-text");
  input.style.display = "none";
  setValueReceived()
  if(props.saveOfCallBack){
    submitAutomation(true)
  }
});
</script>

<template>
  <div class="content-space">
    <div class="space-gap">
      <div class="cards-type-files">
        <input type="file" :accept="acceptInput" ref="fileInput" @change="receiveFile($event)" style="display: none" />
        <div v-for="(item, index) in messagesFileTypes" :key="index" @click="setTypeMessage(item, index)"
          class="card-type" :class="{ 'active-card': selected.index == index }">
          <img v-if="selected.index == index" :src="require('@/assets/icons/' + item.icon + '.svg')" :alt="item.type" />
          <img v-if="!(selected.index == index)" :src="require('@/assets/icons/' + item.icon + '-inactive.svg')"
            :alt="item.type" />
          <span>{{ item.title }}</span>
        </div>
      </div>
      <transition name="fade">
        <div v-show="activeCardSelectFile && switchSendFile" class="content-construction">
          <div class="title-icon">
            <img :src="require('@/assets/icons/' + selected.icon + '.svg')
              " :alt="selected.type" />
            <span class="position-tooltip">{{ selected.title }}
              <img v-b-tooltip.hover v-if="computedTypeMessage.type != 'texto'" src="@/assets/img/icons/info.svg"
                alt="informação: Envio de midias apenas com whatsapp aberto."
                title="⚠️ Envio de mídias apenas com whatsapp aberto" />
            </span>
          </div>
          <BaseButton @click="selectFile(selected.type)" variant="primary-ligth">
            <span v-if="!file" style="color: var(--greenn)">
              {{ getTextButton }}
            </span>
            <div v-else-if="getNameFile" style="display: flex;">
              <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path style="color: var(--greenn)"
                  d="M17.1166 9.54201L9.18138 17.5439C8.87349 17.8576 8.50615 18.1069 8.10084 18.2769C7.69554 18.4471 7.26037 18.5347 6.82081 18.5347C6.38124 18.5347 5.94609 18.4471 5.54077 18.2769C5.13547 18.1069 4.76813 17.8576 4.46024 17.5439L2.08634 15.13C1.46489 14.4988 1.11658 13.6486 1.11658 12.7628C1.11658 11.877 1.46489 11.0267 2.08634 10.3956L10.5417 1.91351C10.7897 1.66351 11.0847 1.46508 11.4097 1.32966C11.7347 1.19425 12.0834 1.12453 12.4355 1.12453C12.7876 1.12453 13.1362 1.19425 13.4612 1.32966C13.7864 1.46508 14.0813 1.66351 14.3293 1.91351L15.2762 2.86041C15.5262 3.10837 15.7246 3.40338 15.8601 3.72841C15.9954 4.05344 16.0652 4.40208 16.0652 4.7542C16.0652 5.10631 15.9954 5.45495 15.8601 5.77998C15.7246 6.10501 15.5262 6.40002 15.2762 6.64798L7.78103 14.1697C7.65706 14.2949 7.50956 14.3941 7.34703 14.4617C7.18453 14.5294 7.01021 14.5642 6.83415 14.5642C6.65809 14.5642 6.48377 14.5294 6.32125 14.4617C6.15874 14.3941 6.01123 14.2949 5.88725 14.1697L5.42047 13.6897C5.29547 13.5657 5.19626 13.4182 5.12854 13.2557C5.06083 13.0931 5.02599 12.9188 5.02599 12.7428C5.02599 12.5667 5.06083 12.3924 5.12854 12.2299C5.19626 12.0674 5.29547 11.9199 5.42047 11.7959L10.4483 6.80802"
                  stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <p class="max-name-file" style="color: var(--greenn)">{{ getNameFile }}</p>
            </div>
          </BaseButton>
          <span v-if="selected.type != 'buscar'" class="accept-formats">Formatos: {{
            getFormats }} Max: 30Mb</span>
        </div>
      </transition>
    </div>
    <div :class="{ 'distributed-space': props.buttonFooter }">
      <div class="content-info">
        <date-range-picker ref="pickerdate" opens="right" :locale-data="localDate" :showWeekNumbers="false"
          :showDropdowns="true" :timePicker="true" :singleDatePicker="true" :ranges="{}" :autoApply="false"
          v-model="dateRange">
        </date-range-picker>
        <transition name="fade">
          <div v-show="activeDescriptionMessage" class="card-edit">
            <div class="header">
              <img src="@/assets/icons/text-inactive.svg" alt="Icone Texto" />
              <span>Mensagem</span>
            </div>
            <p v-if="!isEditMessage" class="py-2 overflow-text">{{ textMessage }}</p>
            <textarea v-if="isEditMessage" @input="inputSaved()" :disabled="loadingFile" class="input-message"
              type="text" v-model="textMessage" />
            <span class="buttom-edit" @click="setEditMessage"> Editar</span>
          </div>
        </transition>
        <transition name="fade">
          <div v-if="displaysSelectedFile && file" class="card-edit">
            <div class="header">
              <img :src="require('@/assets/icons/' + selected.icon + '-inactive.svg')" :alt="selected.type" />
              <span>{{ selected.type }}</span>
            </div>
            <div class="align-items-center d-flex gx-1">
              <p class="py-2 max-name-file">{{ getNameFile }}
              </p>
              <svg v-if="file.url" @click="openFile()" class="cursor-pointer" data-v-7f1998cc="" width="18" height="20"
                viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path data-v-7f1998cc=""
                  d="M17.1166 9.54201L9.18138 17.5439C8.87349 17.8576 8.50615 18.1069 8.10084 18.2769C7.69554 18.4471 7.26037 18.5347 6.82081 18.5347C6.38124 18.5347 5.94609 18.4471 5.54077 18.2769C5.13547 18.1069 4.76813 17.8576 4.46024 17.5439L2.08634 15.13C1.46489 14.4988 1.11658 13.6486 1.11658 12.7628C1.11658 11.877 1.46489 11.0267 2.08634 10.3956L10.5417 1.91351C10.7897 1.66351 11.0847 1.46508 11.4097 1.32966C11.7347 1.19425 12.0834 1.12453 12.4355 1.12453C12.7876 1.12453 13.1362 1.19425 13.4612 1.32966C13.7864 1.46508 14.0813 1.66351 14.3293 1.91351L15.2762 2.86041C15.5262 3.10837 15.7246 3.40338 15.8601 3.72841C15.9954 4.05344 16.0652 4.40208 16.0652 4.7542C16.0652 5.10631 15.9954 5.45495 15.8601 5.77998C15.7246 6.10501 15.5262 6.40002 15.2762 6.64798L7.78103 14.1697C7.65706 14.2949 7.50956 14.3941 7.34703 14.4617C7.18453 14.5294 7.01021 14.5642 6.83415 14.5642C6.65809 14.5642 6.48377 14.5294 6.32125 14.4617C6.15874 14.3941 6.01123 14.2949 5.88725 14.1697L5.42047 13.6897C5.29547 13.5657 5.19626 13.4182 5.12854 13.2557C5.06083 13.0931 5.02599 12.9188 5.02599 12.7428C5.02599 12.5667 5.06083 12.3924 5.12854 12.2299C5.19626 12.0674 5.29547 11.9199 5.42047 11.7959L10.4483 6.80802"
                  stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                  style="color: var(--gray-300);"></path>
              </svg>
            </div>
            <span class="buttom-edit" @click="clearMidia()"> Remover</span>
          </div>
        </transition>
        <div v-if="displaysSelectedData" class="card-edit">
          <div class="header">
            <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.0042 5.92308C10.0042 5.45087 9.62137 5.06808 9.14917 5.06808C8.67697 5.06808 8.29417 5.45087 8.29417 5.92308H10.0042ZM9.14917 9H8.29417C8.29417 9.20426 8.3673 9.40177 8.50032 9.55679L9.14917 9ZM11.6265 13.1999C11.934 13.5582 12.4738 13.5994 12.8321 13.2919C13.1905 12.9844 13.2317 12.4446 12.9242 12.0863L11.6265 13.1999ZM9.14917 17.855C14.0397 17.855 18.0042 13.8905 18.0042 9H16.2942C16.2942 12.9461 13.0953 16.145 9.14917 16.145V17.855ZM18.0042 9C18.0042 4.10952 14.0397 0.145 9.14917 0.145V1.855C13.0953 1.855 16.2942 5.05392 16.2942 9H18.0042ZM9.14917 0.145C4.25869 0.145 0.29417 4.10952 0.29417 9H2.00417C2.00417 5.05393 5.2031 1.855 9.14917 1.855V0.145ZM0.29417 9C0.29417 13.8905 4.25869 17.855 9.14917 17.855V16.145C5.20309 16.145 2.00417 12.9461 2.00417 9H0.29417ZM8.29417 5.92308V9H10.0042V5.92308H8.29417ZM8.50032 9.55679L11.6265 13.1999L12.9242 12.0863L9.79803 8.44321L8.50032 9.55679Z"
                fill="#81858E" />
            </svg>
            <span>Data e hora</span>
          </div>
          <p class="py-2">{{ dataSendMessage }}</p>
          <div :disabled="loadingFile" @click.stop="openRangePicker" class="card-date">
            <span class="buttom-edit"> Editar </span>
          </div>
        </div>
      </div>
      <div class="w-100 d-flex justify-content-center" :class="{ 'my-3': !props.buttonFooter }">
        <BaseButton v-if="!saveOfCallBack" :class="{ 'mt-2': props.buttonFooter }" class="w-100"
          :variant="loadingFile ? 'secondary' : 'primary'" :disabled="loadingFile" @click="submitAutomation">
          {{ returnStatus }}
        </BaseButton>
      </div>
    </div>
    <MidiaLibrary @sendMidia="receiveMidia($event)" />
    <ModalViewMidia v-if="displaysSelectedFile" :midias="file" />
  </div>
</template>

<style lang="scss" scoped>
/* 
  ATENÇÃO: Verifique as instâncias do componente apos modificar.
*/
.position-tooltip {
  display: flex;
  gap: 0.3rem;
  align-items: center;
}

.cards-type-files {
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr 1fr 1fr;

  .active-card {
    border: dashed 2px #4ea934 !important;

    span {
      color: #4ea934 !important;
    }
  }

  .card-type {
    cursor: pointer;
    border: dashed 2px var(--white-medium);
    width: 91px;
    align-content: center;
    height: 85px;
    display: grid;
    justify-items: center;
    border-radius: 5px;

    img {
      max-width: 20px;
      max-height: 20px;
      fill: var(--gray01);
    }

    span {
      color: var(--gray03);
    }
  }

  .length-text {
    padding: 10px !important;
  }
}

.distributed-space {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.space-gap {
  display: flex;
  gap: 1.5rem;
  flex-flow: column;
  justify-content: flex-start;
}

.content-space {
  padding: 1.5rem;
  display: grid;
  gap: 0.5rem;
}

.overflow-text {
  white-space: pre;
  max-width: 18rem;
  max-height: 10rem;
  text-overflow: ellipsis;
  overflow: hidden;
}

.input-message {
  width: 100%;
  height: 6rem;
  max-height: 20rem;
  margin-top: 1rem;
}

.content-info {
  display: grid;
  gap: 0.5rem;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.content-construction {
  width: 100%;
  border-radius: 10px;
  border: solid 1px #4ea934;
  flex-flow: column;
  display: flex;
  gap: 1.3rem;
  position: relative;
  padding: 1.5rem 1rem 2rem 1rem;

  .accept-formats {
    position: absolute;
    font-size: 13px;
    bottom: 0.4rem;
    left: 1.5rem;
    color: var(--gray-300);
  }

  .title-icon {
    display: flex;
    gap: 0.6rem;
    align-items: center;

    img {
      width: 16px;
      height: 16px;
    }

    span {
      color: #4ea934;
    }
  }
}

.max-name-file {
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-edit {
  background-color: var(--white-medium);
  padding: 1.5rem 1rem;
  border-radius: 10px;

  .gx-1 {
    gap: 0.5rem
  }

  .header {
    display: flex;
    gap: 0.6rem;
    align-items: center;

    img {
      height: 16px;
      width: 16px;
    }

    span {
      color: #81858e;
    }
  }

  p {
    font-size: 15px;
    font-weight: 400;
    line-height: 22.5px;
    text-align: left;
  }

  .card-date {
    width: 100%;
    position: relative;
  }

  .buttom-edit {
    cursor: pointer;
    font-size: 13px;
    font-weight: 400;
    line-height: 19.5px;
    text-align: left;
    color: #2897ff;
  }
}
</style>