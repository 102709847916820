<template>
  <BaseModal
    name="ModalEnviarEmailAtividadeLead"
    id="ModalEnviarEmailAtividadeLead"
    idModal="ModalEnviarEmailAtividadeLead"
    size="xl"
    title="Enviar e-mail"
    @shown="openModal"
  >
    <div class="grid-1">
      <div style="display: grid; gap: 20px; grid-template-columns: 1fr 1fr;">
        <div>
          <div>
            <p class="guia">Qual o título do seu e-mail?</p>
            <input
              v-model="titulo"
              class="w-100"
              type="text"
              name="nome"
              id="nome"
            />
          </div>
          <div v-if="valid_remetente == 'not_verified'" class="danger-card">
            <p>
              Não foi feito a verificação do remetente. Por favor, verifique para
              continuar.
            </p>
          </div>
        </div>
        <div>
          <p class="guia">Quem será o remetente?</p>
          <BaseSelect
            :selectModel="remetente"
            class="multiselect"
            label=""
            trackname="email"
            track-by="id"
            selectLabel=""
            deselectLabel=""
            selectedLabel=""
            open-direction="bottom"
            :array="remetentes"
            :multiple="false"
            :allow-empty="false"
            :searchable="false"
            :internal-search="false"
            :clear-on-select="false"
            :close-on-select="true"
            :show-no-results="true"
            :hide-selected="true"
            @callback="getSenders($event)"
            @change="remetente = $event"
          >
          </BaseSelect>
        </div>
      </div>
      <div class="content-email-total">
        <div id="example" class="content-email-editor">
          <div id="bar">
            <EmailEditor
              ref="emailEditor"
              style="border-radius: 10px; overflow: hidden; border: 1px solid var(--white-medium);"
              v-on:load="editorLoaded"
              v-on:designUpdated="designUpdated"
              :designTags="designTags"
              :options="options_unlayer"
              locale="pt-BR"
              :translations="traducaoUnlayer"
              :projectId="168946"
              :templateId="369449"
              apiKey="xTBzcM8XoHvPMhcWa1H83iT6j3qqTuddUfsLGFhiLO5SI9ET2XSqbHIqgmHUh1cI"
            />
          </div>
        </div>
      </div>
    </div>

    <template v-slot:footer="{}">
      <BaseButton
        @click="onSubmit"
        variant="primary"
      >
        Disparar e-mail</BaseButton
      >
    </template>
  </BaseModal>
</template>
<script>
import BaseButton from "@/components/BaseButton";
import EmailService from "@/services/resources/EmailService";
const serviceEmail = EmailService.build();
import SettingsService from "@/services/resources/SettingsService";
const serviceSettings = SettingsService.build();
import moment from "moment";
import { EmailEditor } from "vue-email-editor";
import traducao_unlayer from "@/services/libs/traducao_unlayer.js";

export default {
  props: ["selected_activity", "selected_lead"],
  components: {
    BaseButton,
    EmailEditor,
  },
  data() {
    return {
      titulo: "",
      remetente: null,
      remetentes: [],
      btn_selected: "blank",
      legados: [],
      legados_page: 1,
      atuais_page: 1,
      legados_last_page: 1,
      atuais_last_page: 1,
      atuais: [],
      loading: false,
      html: "",
      valid_remetente: "",
      htmlmail: ``,
      template_selected: ``,
      designTags: {
        business_name: this.$store.getters["getNameSystem"],
        current_user_name: this.$store.getters["getNameSystem"],
        link_botao: "https://google.com.br",
      },
      options_unlayer: {
        mergeTags: [
          { name: "Nome do cliente", value: "{{nome}}" },
          { name: "Primeiro nome do cliente", value: "{{primeiro_nome}}" },
          { name: "E-mail do cliente", value: "{{lead}}" },
        ],
        features: {
          textEditor: {
            cleanPaste: false,
          },
        },
        designTags: {
          business_name: "",
          current_user_name: "",
          link_botao: "https://google.com.br",
        },
      },
      traducaoUnlayer: traducao_unlayer,
      raw: {},
      stop: false,
    };
  },
  methods: {
    cancelEnv() {
      this.$emit("cancelEnvEmail");
      this.$bvModal.hide("ModalEnviarEmailAtividadeLead");
    },
    openModal() {
      const { title, body,json } = this.selected_activity
      this.titulo = title;
      this.html = body;
      this.raw = JSON.parse(json);
      this.$refs.emailEditor.editor.loadDesign(
        JSON.parse(json)
      );
    },
    editorLoaded() {
      this.$refs.emailEditor.editor.setLocale('pt-BR');
    },
    designUpdated() {
      this.$refs.emailEditor.editor.setLocale('pt-BR');
      this.$refs.emailEditor.editor.exportHtml((data) => {
        this.html = data.html;
        this.raw = data.design;
      });
    },
    getSenders(page = 1) {
      if (this.stop) {
        return;
      }
      serviceSettings
        .read("/sender?page=" + page)
        .then((resp) => {
          if (resp.data.length < resp.per_page) {
            this.stop = true;
          }
          this.remetentes = this.remetentes.concat(resp.data);
        })
        .catch((err) => {
        });
    },
    onSubmit() {
      if (!this.titulo || !this.remetente.id) {
        this.$grToast.toast("Preencha todos os campos",
          {
            title: "Enviar um e-mail",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          }
        );
        return
      }

      const leadId = this.$route.params.id || this.selected_lead.id
      var data = {
        callbacks: [],
        filter: { ids: [leadId] },
        type: "broadcast",
        send_at: moment().format("YYYY-MM-DD HH:mm:ss"),
        title: this.titulo,
        html: this.html,
        json: JSON.stringify(this.raw),
        status: 0,
        id: "",
        // selected_count: this.leads.length,
        selected_count: 1,
        from_id: this.remetente.id,
      };
      serviceEmail
        .create(data)
        .then((resp) => {
        this.$grToast.toast(
          "O e-mail entrou na fila para ser enviado. Você pode acompanhar o status em seu broadcast.",
          {
            title: "Enviar um e-mail",
            variant: "info",
            autoHideDelay: 5000,
            appendToast: true,
          }
        );
        this.$emit('submit')
        this.$bvModal.hide("ModalEnviarEmailAtividadeLead");
      });
    },
  },
};
</script>

<style scoped lang="scss">
.guia {
  font-size: 14px;
  font-weight: 600;
  margin: 5px 15px;
  line-height: 1.5;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    width: 9px;
    height: 9px;
    background: var(--greenn);
    border-radius: 1px;
    top: 6px;
    left: -13px;
  }
}
.button-group {
  display: flex;
  gap: 10px;
}
.btn-icon {
  display: flex;
  align-items: center;
  align-items: center;
  gap: 10px;
  &:hover {
    path {
      fill: white !important;
    }
  }
}
.grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.grid-1 {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}
#bar {
  width: 100%;
}
.button-group .active {
  background: var(--greenn);
  path {
    fill: white !important;
  }
  color: white;
}
.templates {
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}
.templates::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
}
.templates::-webkit-scrollbar-track {
  background-color: transparent;
}
.templates::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}

::-webkit-scrollbar-track {
  background: transparent;
}
.around {
  width: 100%;
  justify-content: space-around;
}
.card-email {
  width: 100%;
  height: 500px;
  margin-top: 80px;
  background: #ffffff;
  border: 1px solid #ededf0;
  box-sizing: border-box;
  border-radius: 10px;
}
.danger-card {
  background-color: #ff848f4d;
  border: 1px solid #ed0821bd;
  padding: 15px;
  border-radius: 10px;
  p {
    color: var(--red);
    font-size: 13px;
    margin: 0;
  }
}
.titulo-template {
  display: flex;
  align-items: center;
  justify-content: space-between;
  input {
    width: 65%;
  }
}
.gap {
  display: flex;
  gap: 10px;
  margin-bottom: 14px;
  button {
    height: 50px !important;
  }
}
</style>
