<script setup>
import { ref, defineEmits, onMounted, onUpdated, onUnmounted } from 'vue'

const emit = defineEmits(['updateFilter'])

const filters = ref([
  {icon: '', text: 'Todas', value: null},
  {icon: require('@/assets/img/icons/filterStoryLeads/tags.svg'), text: 'Tags', value: 'tags'},
  {icon: require('@/assets/img/icons/filterStoryLeads/crm.svg'), text: 'CRM', value: 'crm'},
  {icon: require('@/assets/img/icons/filterStoryLeads/seller.svg'), text: 'Vendedor', value: 'seller'},
  {icon: require('@/assets/img/icons/filterStoryLeads/form.svg'), text: 'Formulário', value: 'form'},
  {icon: require('@/assets/img/icons/filterStoryLeads/email.svg'), text: 'E-mail', value: 'email'},
  {icon: require('@/assets/img/icons/filterStoryLeads/activity.svg'), text: 'Atividades', value: 'activity'},
])

const selectedFilter = ref(0);
const containerFilters = ref(null);
const containerHistory = ref(null);
const arrowsShow = ref({
  left: false,
  right: false
})

function selectFilter(index){
  selectedFilter.value = index
  emit('updateFilter', filters.value[index])
}

function selectedFilterClass(index) {
  return selectedFilter.value === index;
};

function scroll(amount) {
  if (containerFilters.value) {
    containerFilters.value.scrollBy({ left: amount, behavior: 'smooth' });
  }
}

function validateShow(){
  const container = containerFilters.value;
  const mainContainer = containerHistory.value;

  if (!container || !mainContainer) return;

  arrowsShow.value.left = container.scrollLeft > 0;
  arrowsShow.value.right = container.scrollLeft + mainContainer.clientWidth < container.scrollWidth;
}

onMounted(() => {
  window.addEventListener('resize', validateShow);

  if (containerFilters.value) {
    containerFilters.value.addEventListener('scroll', validateShow);
  }
});

onUnmounted(() => {
  window.removeEventListener('resize', validateShow);

  if (containerFilters.value) {
    containerFilters.value.removeEventListener('scroll', validateShow);
  }
});

onUpdated(validateShow);

defineExpose({
  validateShow
});


</script>

<template>
  <div 
    ref="containerHistory" 
    class="container-history-filter"
  >
    <h4>Filtrar histórico</h4>
    <div 
      ref="containerFilters"
      class="container-filters"
    >
      <div 
        v-for="filter, index in filters"
        :key="index"
        @click="selectFilter(index)"
        class="card-filter"
        :class="{'selected-filter' : selectedFilterClass(index)}"
      >
        <img  v-show="index != 0" :src="filter.icon" alt="">
        <p>{{ filter.text }}</p>
      </div>
    </div>
    <div 
      class="arrow arrow-left"
      :class="{'visibility' : arrowsShow.left}"
    >
      <div @click="scroll(-150)" >
        <img src="@/assets/img/icons/filterStoryLeads/arrowLeft.svg" alt="Seta para a esquerda">
      </div>
    </div>
    <div 
      class="arrow arrow-right"
      :class="{'visibility' : arrowsShow.right}"
    >
      <div @click="scroll(150)">
        <img src="@/assets/img/icons/filterStoryLeads/arrowRight.svg" alt="Seta para a direita">
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container-history-filter {
  margin-bottom: 50px;
  position: relative;
  h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0px;
    color: var(--greenn);
    margin-bottom: 20px;
  }
}
.container-filters {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  > div {
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: var(--gray-10);
    border-radius: 10px;
    padding: 10px 15px;
    cursor: pointer;
    transition: 0.3s;
    > p {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: center;
      margin-bottom: 0 !important;
      white-space: nowrap;
    }
    > img {
      height: 20px;
      width: 20px;
    }
  }
}
.selected-filter {
  background-color: var(--greenn) !important;
  > p {
    color: white !important;
  }
  > img {
    filter: invert(1) !important;
  }
}
.arrow {
  position: absolute;
  top: 44px;
  height: 44px;
  width: 80px;
  z-index: -1;
  opacity: 0;
  transition: 0.3s;
  > div {
    position: absolute;
    background-color: var(--gray-10);
    padding: 10px;
    border-radius: 100%;
    opacity: 0;
    z-index: -1;
    transition: 0.3s;
    cursor: pointer;
  }
  &-left{
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0px, rgba(255, 255, 255, 1) 45px, transparent 80px);
    left: 0;
  }
  &-right{
    background: linear-gradient(to left, rgba(255, 255, 255, 1) 0px, rgba(255, 255, 255, 1) 45px, transparent 80px);
    right: 0;
    > div {
      right: 0;
    }
  }
}
.visibility {
  opacity: 1;
  z-index: 2;
  > div {
    opacity: 1;
    z-index: 2 ;
  }
}
</style>