<template>
  <BaseModal
    name="ModalMnsgWhatsCtrl"
    id="ModalMnsgWhatsCtrl"
    idModal="ModalMnsgWhatsCtrl"
    size="md"
    title="Enviar mensagem no WhatsApp"
    @shown="limparCampo"
  >
    <b-row v-if="!loading">
      <b-col cols="12">
        <div class="top-dados">
          <div>
            <p>
              Insira a mensagem que deseja enviar a todos os leads selecionados
            </p>
          </div>
        </div>
        <b-form data-vv-name="nome">
          <b-row>
            <b-col>
              <b-form-group label="Mensagem">
                <b-form-textarea
                  :placeholder="`Olá, {{nome}}! Tudo bem? Me chamo {{usuario}}`"
                  id="textarea"
                  v-model="mensage"
                  no-resize
                  style="height: 200px !important"
                ></b-form-textarea>
                <b-form-invalid-feedback :state="!errors.has('mensage')">
                  Por favor, insira uma mensagem
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Cancelar
      </BaseButton>
      <BaseButton variant="black" @click="onSubmit" :disabled="loading">
        Enviar mensagem
      </BaseButton>
    </template>

    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </BaseModal>
</template>
<script>
import Multiselect from "vue-multiselect";

import MensageService from "@/services/resources/MensageService";
const mensageService = MensageService.build();

import moment from "moment-timezone";
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      mensage: "",
      loading: false,
    };
  },
  computed: {
    forms() {
      return this.$store.getters.forms;
    },
  },
  props: ["whatsCtrl"],
  methods: {
    limparCampo() {
      this.mensage = "Olá, {{nome}}! Tudo bem? Me chamo {{usuário}}";
    },
    onSubmit() {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.mensage === "") {
            this.$grToast.toast("Obrigatório preencher o campo de texto", {
              title: "Pipeline",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.loading = false;
            return;
          }
          const messageCopy = this.mensage
          for (let x = 0; x < this.whatsCtrl.length; x++) {
            const id = this.whatsCtrl[x];
            var data = {
              message: messageCopy,
              id: `/send/${id}`,
              date_added: moment().format("YYYY-MM-DD HH:mm:ss"),
            };
            mensageService
              .createId(data)
              .then((resp) => {
                this.$grToast.toast("Mensagem enviada com sucesso", {
                  title: "Pipeline",
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
              })
              .catch((err) => {
                this.$grToast.toast("Erro ao enviar mensagem", {
                  title: "Pipeline",
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
                this.$bvModal.hide("ModalMnsgWhatsCtrl");
              })
              .finally(() => {
                this.loading = false;
                this.$bvModal.hide("ModalMnsgWhatsCtrl");
                this.$emit("atualizar");
                this.mensage = "Olá, {{nome}}! Tudo bem? Me chamo {{usuário}}";
              });
          }
        }
      });
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
p + p {
  margin-top: 5px;
}
.link-documentacao {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}
.top-dados {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .top-dados {
    display: block;
  }
  .top-dados #limpar {
    margin-top: 15px;
  }
}
.form-relative {
  position: relative;
}
.btn-todos {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: #81858e;
}
</style>
